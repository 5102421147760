import axios, { CancelTokenSource } from 'axios'
import { debounce } from 'lodash'
import moment from 'moment'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import Btn from '../../../../components/button'
import DeviceLocationForm from '../../../../components/device-location-form'
import { AlertWarningIcon, CheckIcon, ErrorIcon, ErrorStatusIcon, WarningIcon } from '../../../../components/icon'
import Sidepanel from '../../../../components/sidepanel'
import { AppContext } from '../../../../contexts/app-context'
import UserContext from '../../../../contexts/user-context'
import { GhostButton, LinkButton, SecondaryLinkButton } from '../../../../shared-styles/button.styles'
import {
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTh,
  TableHeaderButtonContainer,
  Td,
  TdAlignRight,
  Th,
} from '../../../../shared-styles/table.styles'
import theme from '../../../../theme'
import API from '../../../../utils/api'
import { DEFAULT_DEVICE_LOCATIONS, TEMP_LEVEL_IN_RANGE } from '../../../../utils/constants'
import {
  convertStringToArray,
  formatDevice,
  getUseCaseIcon,
  hasPermissions,
  isGateway,
  isLeakSensor,
  isMotionSensor,
  isNbiotSensor,
  isProbeSensor,
  isWaterValve,
  parseSensorResponse,
} from '../../../../utils/helpers'
import { useDates, useTemperatures } from '../../../../utils/preference-hooks'
import { getUseCase } from '../../../../utils/usecase-matrix'
import usePauseDevice from '../../../../utils/usePauseDevice'
import { UpdateStateProps, useWebsocket } from '../../../../utils/websockets'
import {
  TroubleshootingPanelConnectionErrorContent as TroubleshootingConnectionErrorBVS,
  TroubleshootingPanelLowSignalContent as TroubleshootingLowSignalBVS,
} from '../../../activating/components/troubleshooting-panels/bvs'
import {
  TroubleshootingPanelConnectionErrorContent as TroubleshootingConnectionErrorGateway,
  TroubleshootingPanelLowSignalErrorContent as TroubleshootingLowSignalGateway,
  TroubleshootingPanelOnBatteryErrorContent as TroubleshootingOnBattGateway,
} from '../../../activating/components/troubleshooting-panels/gateway'
import {
  TroubleshootingPanelConnectionErrorContent as TroubleshootingConnectionErrorLora,
  TroubleshootingPanelLowBatteryContent as TroubleshootingLowBattLora,
  TroubleshootingPanelLowSignalContent as TroubleshootingLowSignalLora,
} from '../../../activating/components/troubleshooting-panels/lora'
import {
  TroubleshootingPanelConnectionErrorContent as TroubleshootingConnectionErrorLoraV3,
  TroubleshootingPanelLowBatteryContent as TroubleshootingLowBattLoraV3,
  TroubleshootingPanelLowSignalContent as TroubleshootingLowSignalLoraV3,
} from '../../../activating/components/troubleshooting-panels/lora-v3'
import {
  TroubleshootingPanelConnectionErrorContent as TroubleshootingConnectionErrorNbiot,
  TroubleshootingPanelLowBatteryContent as TroubleshootingLowBattNbiot,
  TroubleshootingPanelLowSignalContent as TroubleshootingLowSignalNbiot,
} from '../../../activating/components/troubleshooting-panels/nbiot'
import { parseAlarm } from '../../methods/genericMethods'
import { AreaChart } from './../../../../components/chart/index'
import {
  DataCellHeading,
  DataCellOffline,
  DataCellTd,
  DataCellValue,
  DataRow,
  DeviceHeader,
  DeviceHeaderInformation,
  DeviceImageContainer,
  DeviceInfo,
  ShutoffButtonContainer,
  ShutoffConfirmationButtonContainer,
  StatusRow,
  StatusTime,
} from './single-device.styles'

interface AlarmDisplayProps {
  device: IDeviceType
  deviceAlarms: IAlertHistory[]
  openTroubleshootingPanel?: (key: string) => void
  type?: 'active' | 'resolved'
}

const AlarmDisplay = ({ device, deviceAlarms, openTroubleshootingPanel, type = 'active' }: AlarmDisplayProps) => {
  return (
    <>
      {deviceAlarms.map(alarm => {
        return (
          <tr key={`alarm-${alarm.date.replace(' ', '')}`} className="fs-exclude">
            <StatusRow>
              <div>
                {alarm.name.toLowerCase().includes('offline') && (
                  <ErrorIcon fill={type === 'resolved' ? '#D3D4D8' : undefined} />
                )}
                {alarm.tags.includes('warning') && <WarningIcon fill={type === 'resolved' ? '#D3D4D8' : undefined} />}
                {alarm.tags.includes('urgent') && (
                  <ErrorStatusIcon width={20} height={20} fill={type === 'resolved' ? '#D3D4D8' : undefined} />
                )}
                <span>
                  {parseAlarm(alarm)}{' '}
                  {parseSensorResponse(alarm.name) && openTroubleshootingPanel ? (
                    <SecondaryLinkButton
                      onClick={() =>
                        openTroubleshootingPanel(`${device.nodeTypeName}__${parseSensorResponse(alarm.name)}`)
                      }
                    >
                      Troubleshoot device
                    </SecondaryLinkButton>
                  ) : null}
                </span>
              </div>
              <StatusTime>{moment(alarm.date).fromNow()}</StatusTime>
            </StatusRow>
          </tr>
        )
      })}
    </>
  )
}

interface DataSeriesType {
  x: string
  y: number
}

interface DataChartProps {
  tempUnits: string
  data: DataSeriesType[]
  name: string
  fillColor: string
  strokeColor: string
  curve?: 'smooth' | 'straight' | 'stepline'
  channel?: 'temp' | 'leak' | 'powersource' | 'hum'
}
const DataChart = ({ tempUnits, data, name, fillColor, strokeColor, curve = 'smooth', channel }: DataChartProps) => {
  return (
    <StyledTable className="fs-exclude">
      <thead>
        <tr>
          <StyledTh>
            {name} {tempUnits && <>({tempUnits})</>}
          </StyledTh>
        </tr>
      </thead>
      <tbody>
        <tr>
          <StyledTd>
            <AreaChart
              unit={tempUnits}
              series={[
                {
                  name,
                  fillColor,
                  strokeColor,
                  data,
                },
              ]}
              curve={curve}
              channel={channel}
            />
          </StyledTd>
        </tr>
      </tbody>
    </StyledTable>
  )
}

interface IProps {
  device: IDeviceType
  showModal: boolean
  closeModal: () => void
  showBackButton: boolean
  openGateway: (gateway: IDeviceType) => void
  onBack?: () => void
}

/** Displays a modal (sidepanel) for a single device */
const ModalSingleDevice = ({
  device: propDevice,
  showModal,
  closeModal,
  showBackButton,
  openGateway,
  onBack,
}: IProps) => {
  const _isMounted = useRef(true)
  const handleError = useErrorHandler()

  const { user, permissions, roles } = useContext(AppContext)
  const { devices, companyBranding } = useContext(UserContext)
  const { formatTemperature, tempUnits } = useTemperatures()
  const { formatDateTime } = useDates()

  // This allow us to cancel an axios call in the useEffect cleanup function when unmounting the component
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source())
  const [singleDevice, setSingleDevice] = useState<IDeviceType>(propDevice)
  const device = singleDevice || propDevice
  const deviceAlarms = device.alerts
  const activeDeviceAlarms = deviceAlarms.filter(a => a.status === 'active')
  const resolvedDeviceAlarms = deviceAlarms.filter(a => a.status === 'resolved')

  const [modalKey, setModalKey] = useState<string>('main')
  const [backButtonText, setBackButtonText] = useState<string>('Back to All Type/Location Sensors')
  const [modifyShowBackButton, setModifyShowBackButton] = useState<boolean>(showBackButton)
  const [temperatureData, setTemperatureData] = useState<DataSeriesType[] | undefined>()
  const [humidityData, setHumidityData] = useState<DataSeriesType[] | undefined>()
  const [leakData, setLeakData] = useState<DataSeriesType[] | undefined>()
  const [powersourceData, setPowersourceData] = useState<DataSeriesType[] | undefined>()
  const [valveIsWaiting, setValveIsWaiting] = useState<boolean>(false)

  const hasValveShutoffPermissions = user ? hasPermissions('Operate Shutoff Valve', permissions, roles, user) : false
  const deviceLocations = companyBranding?.deviceLocations
    ? typeof companyBranding?.deviceLocations === 'string'
      ? convertStringToArray(companyBranding?.deviceLocations)
      : companyBranding?.deviceLocations
    : DEFAULT_DEVICE_LOCATIONS

  // Clean up when the component unmounts
  useEffect(() => {
    return () => {
      source.cancel('cancelled') // clean up axios calls when component unmounts
      _isMounted.current = false // used to handle memory leaks when performing a state change when the component has already unmounted
    }
  }, [source])

  // Websockets
  const updateState = useCallback((props: UpdateStateProps) => {
    const { payload, channelValue, timestamp } = props
    const { channelName, value } = channelValue
    const newChannelValue: ICurrentValueType = {}
    newChannelValue[channelName] = {
      createdAt: timestamp,
      timestamp,
      value,
    }
    setSingleDevice(prev => ({
      ...prev,
      ...payload,
      currentValues: {
        ...prev.currentValues,
        ...newChannelValue,
      } as unknown as ICurrentValueType,
    }))
    if (channelName === 'valve') setValveIsWaiting(false)
  }, [])
  // Handle events that come in through websockets
  const debouncedDataFetch = useRef(
    debounce(async () => {
      try {
        const res = (
          await API.get(`/api/v2/protect/devices/${singleDevice.id}`, {
            cancelToken: source.token,
          })
        ).data as IDeviceTypeAPI
        setSingleDevice(prev => ({
          ...prev,
          lastCheckIn: res.lastCheckIn,
          nextCheckIn: res.nextCheckIn,
          isOffline: res.isOffline,
          hasLowSignal: res.hasLowSignal,
          hasLowBattery: res.hasLowBattery,
          activeAlertsCount: res.alerts.filter(a => a.status === 'active').length,
          alerts: res.alerts,
          currentValues: res.currentValues,
        }))
      } catch {
        // Do nothing, this is in the background
      }
    }, 300)
  ).current
  useEffect(() => {
    return () => {
      debouncedDataFetch.cancel()
    }
  }, [debouncedDataFetch])
  const handleEvent = () => {
    setTimeout(debouncedDataFetch, 15000) // 15 seconds to account for a delay between when we receive a websocket message and when alerts on the device endpoint is updated
  }
  useWebsocket({
    shouldBeAlive: _isMounted.current,
    subscribeIds: [singleDevice.id],
    updateState,
    handleEvent,
  })

  const { isPaused, willBePaused, pausedText } = usePauseDevice({
    folderId: device.folderId,
    device,
  })

  // Get this device
  useEffect(() => {
    const getData = async () => {
      try {
        const apiData = (
          await API.get(`/api/v2/protect/devices/${device.id}`, {
            cancelToken: source.token,
          })
        ).data as IDeviceTypeAPI
        if (_isMounted.current) setSingleDevice(formatDevice(apiData))
      } catch (e) {
        if (e.message !== 'cancelled') handleError(e)
      }
    }
    getData()
  }, [device.id, handleError, source])

  // Get two weeks of channel data for this device to display in graph
  // Temperature sensor: Temperature and Humidity
  // Leak & Freeze: Temperature and Leak Status
  // Probe sensor: Probe Temperature
  // Gateway: Power Source
  // Water sensor: Leak Status
  useEffect(() => {
    const getData = async (channel: string) => {
      try {
        // Get the data for this channel starting from two weeks ago
        const apiData = await API.post(`/api/data/history`, {
          channelName: channel,
          nodeId: device.id,
          startTime: moment()
            .subtract(14, 'd') // get startTime starting 14 days ago
            .utc()
            .format(),
          endTime: moment()
            .add(1, 'd') // get endTime of 1 day from now; using today's date causes an issue with getting all the data
            .utc()
            .format(),
        })
        // Format the data into the DataSeriesType used by the charts
        return apiData.data.map((d: { timestamp: string; value: number | string }) => {
          return {
            x: d.timestamp,
            y: channel === 'powersource' ? (d.value === 'BATT' ? 1 : 0) : d.value,
          }
        })
      } catch (e) {
        handleError(e)
      }
    }
    const getTemperatureData = async () => {
      const data = await getData('temp')
      if (_isMounted.current) setTemperatureData(data)
    }
    const getProbeTemperatureData = async () => {
      const data = await getData('probe')
      if (_isMounted.current) setTemperatureData(data)
    }
    const getHumidityData = async () => {
      const data = await getData('hum')
      if (_isMounted.current) setHumidityData(data)
    }
    const getLeakData = async () => {
      const data = await getData('leak')
      if (_isMounted.current) setLeakData(data)
    }
    const getPowersourceData = async () => {
      const data = await getData('powersource')
      if (_isMounted.current) setPowersourceData(data)
    }

    if (isMotionSensor(device.nodeTypeName) || isNbiotSensor(device.nodeTypeName)) getTemperatureData()
    if (isProbeSensor(device.nodeTypeName)) getProbeTemperatureData()
    if (isMotionSensor(device.nodeTypeName)) getHumidityData()
    if (isNbiotSensor(device.nodeTypeName) || isLeakSensor(device.nodeTypeName)) getLeakData()
    if (isGateway(device.nodeTypeName)) getPowersourceData()
  }, [device.id, device.nodeTypeName, handleError])

  const backButtonFxn = () => {
    if (modalKey === 'main' && onBack) {
      onBack()
    } else {
      setModalKey('main')
      setModifyShowBackButton(showBackButton)
      setBackButtonText('Back to All Type/Location Sensors')
    }
  }

  const setSecondaryBackButton = () => {
    setModifyShowBackButton(true)
    setBackButtonText('Back to Sensor Details')
  }

  const openTroubleshootingPanel = (key: string) => {
    setModalKey(key)
    setSecondaryBackButton()
  }

  const openHistoryPanel = () => {
    setModalKey('history')
    setSecondaryBackButton()
  }

  const openGraphsPanel = () => {
    setModalKey('graphs')
    setSecondaryBackButton()
  }

  const openEditPanel = () => {
    setModalKey('edit_location')
    setSecondaryBackButton()
  }

  const openShutoffConfirmationPanel = () => {
    setModalKey('shutoff_confirmation')
    setSecondaryBackButton()
  }

  const onDismiss = () => {
    setModalKey('main')
    closeModal()
  }

  const getGateway = (gatewayId?: number) => devices?.find(d => d.id === gatewayId)

  const updateValve = async () => {
    setValveIsWaiting(true)
    try {
      await API.post('/api/v2/devices/commands/shutoff', {
        nodeId: singleDevice.id,
        state: singleDevice?.currentValues?.valve?.value === 'open' ? 'closed' : 'open',
      })
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <Sidepanel
      title={device.vanity}
      showTitle={false}
      openModal={showModal}
      onDismiss={onDismiss}
      showBackButton={modifyShowBackButton}
      backButtonText={backButtonText}
      onBack={backButtonFxn}
    >
      {modalKey === 'main' && (
        <>
          <DeviceHeaderInformation className="fs-exclude">
            <DeviceImageContainer>{getUseCaseIcon(device.deviceUseCase)}</DeviceImageContainer>
            <DeviceHeader data-testid="device-details-heading" aria-hidden={true}>
              {device.vanity}
            </DeviceHeader>
            <DeviceInfo>
              {singleDevice.building && `${singleDevice.building} · `}
              {singleDevice.floor && `${singleDevice.floor} · `}
              {singleDevice.area && `${singleDevice.area}`}
            </DeviceInfo>
            <DeviceInfo style={{ marginBottom: '2.4rem' }}>
              {singleDevice.deviceLocationNotes} <LinkButton onClick={() => openEditPanel()}>Edit Location</LinkButton>
            </DeviceInfo>
          </DeviceHeaderInformation>

          {isWaterValve(singleDevice.nodeTypeName) &&
            hasValveShutoffPermissions &&
            typeof singleDevice.currentValues?.valve?.value === 'string' && (
              <ShutoffButtonContainer>
                <Btn
                  buttonType="tertiary"
                  onClick={() => openShutoffConfirmationPanel()}
                  waiting={valveIsWaiting}
                  secondaryStyling={singleDevice.currentValues?.valve?.value === 'closed'}
                >
                  {singleDevice.currentValues.valve.value === 'open' ? 'Shut Off Water' : 'Turn On Water'}
                </Btn>
              </ShutoffButtonContainer>
            )}

          <StyledTable>
            <thead>
              <tr>
                <StyledTh>
                  <TableHeaderButtonContainer>
                    <div>Status</div>
                    <div>
                      <GhostButton onClick={() => openHistoryPanel()}>View History</GhostButton>
                    </div>
                  </TableHeaderButtonContainer>
                </StyledTh>
              </tr>
            </thead>
            <tbody className="fs-exclude">
              {!device.isOffline && moment(device.lastCheckIn).valueOf() > 0 && (
                <tr>
                  <StatusRow noButton={true}>
                    <CheckIcon />
                    <span>Device communicating regularly.</span>
                  </StatusRow>
                </tr>
              )}

              {(device.isOffline || moment(device.lastCheckIn).valueOf() === 0) && (
                <tr>
                  <StatusRow>
                    <div>
                      <ErrorIcon />
                      <span>
                        Device offline.{' '}
                        <SecondaryLinkButton
                          onClick={() => openTroubleshootingPanel(`${device.nodeTypeName}__connection_error`)}
                        >
                          Troubleshoot device
                        </SecondaryLinkButton>
                      </span>
                    </div>
                    {device.lastCheckIn && moment(device.lastCheckIn).valueOf() > 0 ? (
                      <StatusTime>{moment(device.lastCheckIn).fromNow()}</StatusTime>
                    ) : (
                      <StatusTime>Never checked in</StatusTime>
                    )}
                  </StatusRow>
                </tr>
              )}
              {device.hasLowSignal && (
                <tr>
                  <StatusRow>
                    <div>
                      <ErrorStatusIcon width={20} height={20} />
                      <span>
                        Device has poor signal.{' '}
                        <SecondaryLinkButton
                          onClick={() => openTroubleshootingPanel(`${device.nodeTypeName}__low_signal`)}
                        >
                          Troubleshoot device
                        </SecondaryLinkButton>
                      </span>
                    </div>
                    {device.lastCheckIn && moment(device.lastCheckIn).valueOf() > 0 ? (
                      <StatusTime>{moment(device.lastCheckIn).fromNow()}</StatusTime>
                    ) : (
                      <StatusTime>Never checked in</StatusTime>
                    )}
                  </StatusRow>
                </tr>
              )}
              {device.hasLowBattery && (
                <tr>
                  <StatusRow>
                    <div>
                      <ErrorStatusIcon width={20} height={20} />
                      <span>
                        Device has low battery.{' '}
                        <SecondaryLinkButton
                          onClick={() => openTroubleshootingPanel(`${device.nodeTypeName}__low_batt`)}
                        >
                          Troubleshoot device
                        </SecondaryLinkButton>
                      </span>
                    </div>
                    {device.lastCheckIn && moment(device.lastCheckIn).valueOf() > 0 ? (
                      <StatusTime>{moment(device.lastCheckIn).fromNow()}</StatusTime>
                    ) : (
                      <StatusTime>Never checked in</StatusTime>
                    )}
                  </StatusRow>
                </tr>
              )}
              <AlarmDisplay
                device={device}
                deviceAlarms={activeDeviceAlarms}
                openTroubleshootingPanel={openTroubleshootingPanel}
              />
            </tbody>
          </StyledTable>

          {(isPaused || willBePaused) && (
            <StyledTable>
              <tbody>
                <tr>
                  <StatusRow centerText={true}>{pausedText}</StatusRow>
                </tr>
              </tbody>
            </StyledTable>
          )}

          <StyledTable>
            <thead>
              <tr>
                <StyledTh>
                  <TableHeaderButtonContainer>
                    <div>Data</div>
                    {!isWaterValve(device.nodeTypeName) && (
                      <div>
                        <GhostButton onClick={() => openGraphsPanel()}>View Graphs</GhostButton>
                      </div>
                    )}
                  </TableHeaderButtonContainer>
                </StyledTh>
              </tr>
              {device.isOffline && device.lastCheckIn && (
                <tr className="fs-exclude">
                  <DataCellOffline>
                    Device offline; displaying data from last check in {moment(device.lastCheckIn).fromNow()}.
                  </DataCellOffline>
                </tr>
              )}
            </thead>
            <StyledTbody className="fs-exclude">
              <DataRow>
                {isWaterValve(device.nodeTypeName) && (
                  <DataCellTd>
                    <DataCellHeading>Valve</DataCellHeading>
                    <DataCellValue data-testid="device-valve-status">
                      {device.currentValues?.valve?.value === 'open' && 'Open'}
                      {device.currentValues?.valve?.value === 'closed' && 'Closed'}
                      {device.currentValues?.valve?.value === 'unknown' && 'Unknown'}
                      {device.currentValues?.valve?.value === 'error' && 'Error'}
                    </DataCellValue>
                  </DataCellTd>
                )}

                {!isGateway(device.nodeTypeName) && !isWaterValve(device.nodeTypeName) && (
                  <DataCellTd>
                    <DataCellHeading>Temperature</DataCellHeading>
                    {!isNbiotSensor(device.nodeTypeName) && (
                      <DataCellValue data-testid="device-temperature-status">
                        {isProbeSensor(device.nodeTypeName)
                          ? device.currentValues?.probe?.value
                            ? `${formatTemperature(device.currentValues.probe.value)}${tempUnits}`
                            : '--'
                          : ''}
                        {!isProbeSensor(device.nodeTypeName)
                          ? device.currentValues?.temp?.value
                            ? `${formatTemperature(device.currentValues.temp.value)}${tempUnits}`
                            : '--'
                          : ''}
                      </DataCellValue>
                    )}
                    {isNbiotSensor(device.nodeTypeName) && (
                      <DataCellValue data-testid="device-temperature-status">
                        {Number(device.currentValues?.temp_reporting_level?.value) < TEMP_LEVEL_IN_RANGE ? (
                          'Low'
                        ) : Number(device.currentValues?.temp_reporting_level?.value) === TEMP_LEVEL_IN_RANGE ? (
                          'In Range'
                        ) : Number(device.currentValues?.temp_reporting_level?.value) > TEMP_LEVEL_IN_RANGE ? (
                          <>
                            <ErrorStatusIcon /> High
                          </>
                        ) : (
                          '--'
                        )}
                      </DataCellValue>
                    )}
                  </DataCellTd>
                )}

                {(isLeakSensor(device.nodeTypeName) || isNbiotSensor(device.nodeTypeName)) && (
                  <DataCellTd>
                    <DataCellHeading>Water</DataCellHeading>
                    <DataCellValue data-testid="device-water-status">
                      {device.currentValues?.leak?.value === 1 ? (
                        <>
                          <ErrorStatusIcon /> Detected
                        </>
                      ) : (
                        'No Water'
                      )}
                    </DataCellValue>
                  </DataCellTd>
                )}
                {(isProbeSensor(device.nodeTypeName) || isMotionSensor(device.nodeTypeName)) && (
                  <>
                    <DataCellTd>
                      <DataCellHeading>Humidity</DataCellHeading>
                      <DataCellValue data-testid="device-humidity-status">
                        {device.currentValues?.hum?.value ?? '--'}%
                      </DataCellValue>
                    </DataCellTd>
                  </>
                )}
                {isGateway(device.nodeTypeName) && (
                  <DataCellTd>
                    <DataCellHeading>Power</DataCellHeading>
                    <DataCellValue data-testid="device-power-status">
                      {device.currentValues?.powersource?.value === 'LINE' && 'Line'}
                      {device.currentValues?.powersource?.value === 'BATT' && (
                        <>
                          <AlertWarningIcon /> Battery
                        </>
                      )}
                      {!device.currentValues?.powersource?.value && '--'}
                    </DataCellValue>
                  </DataCellTd>
                )}
                {!isGateway(device.nodeTypeName) && !isWaterValve(device.nodeTypeName) && (
                  <DataCellTd>
                    <DataCellHeading>Battery</DataCellHeading>
                    <DataCellValue data-testid="device-battery-status">
                      {device.currentValues?.battery_indicator?.value === 1 && (
                        <>
                          <AlertWarningIcon /> Low
                        </>
                      )}
                      {device.currentValues?.battery_indicator?.value === 2 && 'Medium'}
                      {device.currentValues?.battery_indicator?.value === 3 && 'Full'}
                      {!device.currentValues?.battery_indicator?.value && '--'}
                    </DataCellValue>
                  </DataCellTd>
                )}
                <DataCellTd>
                  <DataCellHeading>Signal</DataCellHeading>
                  <DataCellValue data-testid="device-signal-status">
                    {(device.currentValues?.signal_indicator?.value === 1 ||
                      device.currentValues?.signal_indicator?.value === 2) && (
                      <>
                        <AlertWarningIcon /> Weak
                      </>
                    )}
                    {device.currentValues?.signal_indicator?.value === 3 && 'Fair'}
                    {device.currentValues?.signal_indicator?.value === 4 && 'Good'}
                    {device.currentValues?.signal_indicator?.value === 5 && 'Excellent'}
                    {!device.currentValues?.signal_indicator?.value && '--'}
                  </DataCellValue>
                </DataCellTd>
              </DataRow>
            </StyledTbody>
          </StyledTable>

          <StyledTable>
            <thead>
              <tr>
                <StyledTh>Details</StyledTh>
                <StyledTh />
              </tr>
            </thead>
            <StyledTbody className="fs-exclude">
              {device.uniqueId && (
                <tr>
                  <TdAlignRight>Device ID:</TdAlignRight>
                  <StyledTd>{device.uniqueId}</StyledTd>
                </tr>
              )}
              {singleDevice.currentValues?.gatewayid?.value && (
                <tr>
                  <TdAlignRight>Gateway ID:</TdAlignRight>
                  <StyledTd>
                    {device.gatewayId && getGateway(device.gatewayId) ? (
                      <Btn
                        buttonType="secondary-link"
                        onClick={() => {
                          const gateway = getGateway(device.gatewayId)
                          if (gateway) openGateway(gateway)
                        }}
                      >
                        {device.currentValues?.gatewayid?.value as string}
                      </Btn>
                    ) : (
                      (device.currentValues?.gatewayid?.value as string)
                    )}
                  </StyledTd>
                </tr>
              )}
              {device.deviceUseCase && (
                <tr>
                  <TdAlignRight>Use Case:</TdAlignRight>
                  <StyledTd>{getUseCase(device.deviceUseCase).vanity}</StyledTd>
                </tr>
              )}
              {device.lastCheckIn && (
                <tr>
                  <TdAlignRight>Last Check-in:</TdAlignRight>
                  <StyledTd>
                    {moment(device.lastCheckIn).valueOf() === 0
                      ? 'Never checked in'
                      : formatDateTime(device.lastCheckIn)}
                  </StyledTd>
                </tr>
              )}
              {singleDevice.currentValues?.device_ownership?.value && (
                <tr>
                  <TdAlignRight>Device Ownership:</TdAlignRight>
                  <StyledTd>Owned by {singleDevice.currentValues?.device_ownership?.value}</StyledTd>
                </tr>
              )}
            </StyledTbody>
          </StyledTable>
        </>
      )}
      {modalKey === 'shutoff_confirmation' && (
        <div className="fs-exclude">
          <h4>
            Are you sure you want to {singleDevice.currentValues?.valve?.value === 'open' ? 'shut off' : 'turn on'} the
            water?
          </h4>
          <p>
            Please confirm that you want to{' '}
            {singleDevice.currentValues?.valve?.value === 'open' ? 'shut off' : 'turn on'} the water.
          </p>
          <ShutoffConfirmationButtonContainer>
            <Btn buttonType="secondary-link" onClick={() => backButtonFxn()}>
              Cancel
            </Btn>
            <Btn
              onClick={() => {
                backButtonFxn()
                updateValve()
              }}
            >
              Confirm
            </Btn>
          </ShutoffConfirmationButtonContainer>
        </div>
      )}
      {modalKey === 'history' && (
        <>
          <StyledTable className="fs-exclude">
            <tbody>
              <tr>
                <Th>Active</Th>
              </tr>
              <AlarmDisplay
                device={device}
                deviceAlarms={activeDeviceAlarms}
                openTroubleshootingPanel={openTroubleshootingPanel}
              />
              {activeDeviceAlarms.length === 0 && (
                <tr>
                  <Td>No active alarms</Td>
                </tr>
              )}
              {resolvedDeviceAlarms.length > 0 && (
                <>
                  <tr>
                    <Th>Resolved</Th>
                  </tr>
                  <AlarmDisplay device={device} deviceAlarms={resolvedDeviceAlarms} type={'resolved'} />
                </>
              )}
            </tbody>
          </StyledTable>
        </>
      )}
      {modalKey === 'graphs' && (
        <div style={{ width: '100%', textAlign: 'left' }} className="fs-exclude">
          <h3>Device Data</h3>
          {temperatureData && (isMotionSensor(device.nodeTypeName) || isProbeSensor(device.nodeTypeName)) && (
            <DataChart
              tempUnits={`°${tempUnits}`}
              data={temperatureData}
              name="Temperature"
              fillColor={`${theme.colors.accent_green}80`}
              strokeColor={theme.colors.accent_green}
              channel="temp"
            />
          )}
          {humidityData && isMotionSensor(device.nodeTypeName) && (
            <DataChart
              tempUnits="%"
              data={humidityData}
              name="Humidity"
              fillColor={`${theme.colors.accent_blue}80`}
              strokeColor={theme.colors.accent_blue}
            />
          )}
          {leakData && (isNbiotSensor(device.nodeTypeName) || isLeakSensor(device.nodeTypeName)) && (
            <DataChart
              tempUnits=""
              data={leakData}
              name="Leak Status"
              fillColor={`${theme.colors.accent_gray}80`}
              strokeColor={theme.colors.accent_gray}
              curve="stepline"
              channel="leak"
            />
          )}
          {powersourceData && (isGateway(device.nodeTypeName) || isWaterValve(device.nodeTypeName)) && (
            <DataChart
              tempUnits=""
              data={powersourceData}
              name="Power Source"
              fillColor={`${theme.colors.accent_orange}80`}
              strokeColor={theme.colors.accent_orange}
              curve="stepline"
              channel="powersource"
            />
          )}
        </div>
      )}
      {modalKey === 'edit_location' && (
        <div style={{ width: '100%', textAlign: 'left' }} className="fs-exclude">
          <h3>Edit Device Location</h3>
          <DeviceLocationForm
            device={singleDevice}
            deviceLocations={deviceLocations}
            onBack={backButtonFxn}
            setDevice={setSingleDevice}
          />
        </div>
      )}
      {modalKey === 'gateway__connection_error' && <TroubleshootingConnectionErrorGateway />}
      {modalKey === 'motion__connection_error' && <TroubleshootingConnectionErrorLora />}
      {modalKey === 'motion2__connection_error' && <TroubleshootingConnectionErrorLora />}
      {modalKey === 'leak__connection_error' && <TroubleshootingConnectionErrorLora />}
      {modalKey === 'leak2__connection_error' && <TroubleshootingConnectionErrorLora />}
      {modalKey === 'probe__connection_error' && <TroubleshootingConnectionErrorLora />}
      {modalKey === 'probe2__connection_error' && <TroubleshootingConnectionErrorLora />}
      {modalKey === 'temp3__connection_error' && <TroubleshootingConnectionErrorLoraV3 />}
      {modalKey === 'leak3__connection_error' && <TroubleshootingConnectionErrorLoraV3 />}
      {modalKey === 'bvs__connection_error' && <TroubleshootingConnectionErrorBVS />}
      {modalKey === 'nbiot_leak__connection_error' && <TroubleshootingConnectionErrorNbiot />}
      {modalKey === 'gateway__on_batt' && <TroubleshootingOnBattGateway />}
      {modalKey === 'motion__low_batt' && <TroubleshootingLowBattLora type="motion" />}
      {modalKey === 'motion2__low_batt' && <TroubleshootingLowBattLora type="motion2" />}
      {modalKey === 'leak__low_batt' && <TroubleshootingLowBattLora type="leak" />}
      {modalKey === 'leak2__low_batt' && <TroubleshootingLowBattLora type="leak2" />}
      {modalKey === 'probe__low_batt' && <TroubleshootingLowBattLora type="probe" />}
      {modalKey === 'probe2__low_batt' && <TroubleshootingLowBattLora type="probe2" />}
      {modalKey === 'temp3__low_batt' && <TroubleshootingLowBattLoraV3 />}
      {modalKey === 'leak3__low_batt' && <TroubleshootingLowBattLoraV3 />}
      {modalKey === 'nbiot_leak__low_batt' && <TroubleshootingLowBattNbiot />}
      {modalKey === 'gateway__low_signal' && <TroubleshootingLowSignalGateway />}
      {modalKey === 'motion__low_signal' && <TroubleshootingLowSignalLora />}
      {modalKey === 'motion2__low_signal' && <TroubleshootingLowSignalLora />}
      {modalKey === 'leak__low_signal' && <TroubleshootingLowSignalLora />}
      {modalKey === 'leak2__low_signal' && <TroubleshootingLowSignalLora />}
      {modalKey === 'probe__low_signal' && <TroubleshootingLowSignalLora />}
      {modalKey === 'probe2__low_signal' && <TroubleshootingLowSignalLora />}
      {modalKey === 'temp3__low_signal' && <TroubleshootingLowSignalLoraV3 />}
      {modalKey === 'leak3__low_signal' && <TroubleshootingLowSignalLoraV3 />}
      {modalKey === 'bvs__low_signal' && <TroubleshootingLowSignalBVS />}
      {modalKey === 'nbiot_leak__low_signal' && <TroubleshootingLowSignalNbiot />}
    </Sidepanel>
  )
}

export default ModalSingleDevice
